import { useEffect } from 'react';

const useChatbotEnhancer = () => {
	useEffect(() => {
		const enhanceIframe = () => {
			const iframe = document.getElementById(
				'airportAI-chat-iframes-chatLauncher',
			);
			if (iframe) {
				iframe.setAttribute('title', 'Airport AI Chat Launcher');
			} else {
				console.warn('Iframe not found!');
			}
		};

		// Call the enhancer when the component is mounted
		enhanceIframe();

		// Optional: Observe mutations in case the iframe is dynamically injected
		const observer = new MutationObserver(() => {
			enhanceIframe(); // Re-check when DOM changes
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});

		// Cleanup the observer when the component unmounts
		return () => {
			observer.disconnect();
		};
	}, []);
};

export default useChatbotEnhancer;
