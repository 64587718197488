import log from 'loglevel';
import { InferType, string } from 'yup';
import { getPublicConfig, publicConfigSchema } from './getPublicConfig';
import { removeEmptyValues } from './removeEmptyValues';
import { Environment } from './types/Environment';

export const buildConfigSchema = publicConfigSchema
	.shape({
		contentfulSpace: string().required(),
		contentfulEnvironment: string().required(),
		contentfulAccessToken: string().required(),
		contentfulAccessTokenPreview: string().required(),
		firebaseApiKey: string().required(),
		firebaseAuthDomain: string().required(),
		firebaseDatabaseUrl: string().required(),
		firebaseProjectId: string().required(),
		firebaseStorageBucket: string().required(),
		firebaseMessagingSenderId: string().required(),
		firebaseAppId: string().required(),
	})
	.required();

export type BuildConfig = InferType<typeof buildConfigSchema>;

let buildConfig: BuildConfig | undefined;

export function getBuildConfig() {
	if (buildConfig) {
		return buildConfig;
	}

	const publicConfig = getPublicConfig();

	const defaults: Partial<BuildConfig> = {
		...publicConfig,
		...getBuildConfigForEnvironment(publicConfig.environment as Environment),
	};

	const custom = getCustomBuildConfig();

	buildConfig = buildConfigSchema.validateSync({
		...defaults,
		...custom,
	});

	return buildConfig;
}

function getBuildConfigForEnvironment(environment?: Environment) {
	if (!environment) {
		return {};
	}

	const genericFirebaseConfig = {
		firebaseApiKey: 'AIzaSyDdh0bV4I-qZIUm-34Uo5k8KXqjpm2alA0',
		firebaseAuthDomain: 'dfw-airport-6c7a6.firebaseapp.com',
		firebaseDatabaseUrl: 'https://dfw-airport-6c7a6.firebaseio.com',
		firebaseProjectId: 'dfw-airport-6c7a6',
		firebaseStorageBucket: 'dfw-airport-6c7a6.appspot.com',
		firebaseMessagingSenderId: '656657328165',
	};

	switch (environment) {
		case Environment.Development:
			return {
				contentfulEnvironment: 'dev',
				...genericFirebaseConfig,
				firebaseAppId: '1:656657328165:web:3c4d2c9abfdd21307237cc',
			};

		case Environment.Test:
			return {
				contentfulEnvironment: 'test',
				...genericFirebaseConfig,
				firebaseAppId: '1:656657328165:web:d86e07d8d0a9db397237cc',
			};

		case Environment.Acceptance:
			return {
				contentfulEnvironment: 'acceptance',
				...genericFirebaseConfig,
				firebaseAppId: '1:656657328165:web:658a1cb96f76f72c7237cc',
			};

		case Environment.Ghost:
			return {
				contentfulEnvironment: 'master',
				...genericFirebaseConfig,
				firebaseAppId: '1:656657328165:web:658a1cb96f76f72c7237cc',
			};

		case Environment.Production:
			return {
				contentfulEnvironment: 'master',
				...genericFirebaseConfig,
				firebaseAppId: '1:656657328165:web:148dcf8c467137ae7237cc',
			};

		default:
			log.warn('No default build config for unknown environment', environment);
			return {};
	}
}

function getCustomBuildConfig() {
	const customConfig: Partial<BuildConfig> = {
		contentfulSpace: process.env.CONTENTFUL_SPACE ?? 'm2p70vmwc019',
		contentfulEnvironment: process.env.CONTENTFUL_ENVIRONMENT ?? 'test',
		contentfulAccessToken:
			process.env.CONTENTFUL_ACCESS_TOKEN ??
			'mID0H26mnyoFVSXwwDjTGGhlUi4YV6E8D_aN4pmOeo8',
		contentfulAccessTokenPreview:
			process.env.CONTENTFUL_ACCESS_TOKEN_PREVIEW ??
			'C52nazJoLVxv_a7nUrCW4h9VHDStKVuBQZjGJQHFz2o',
	};

	return removeEmptyValues(customConfig);
}
